import styled from "styled-components";
import "../../font/font.css";
import {
    a10950,
    ownerTel,
    ownerKakao,
    ownerMain1,
    ownerMainA1,
    ownerMain2A2,
    ownerMain2A1,
    ownerMain2A3,
    headerLogo,
    ownerMain3Tel,
    ownerMain3Icon,
    mainImage,
    mainImage2x,
    ownerLogin,
    ownerMain1A1,
    ownerMain3Title,
    ownerMain2TileWeb,
    ownerMain1Web,
    google2, app2
} from "../../images";


const Main1 = styled.div`
  width: 100%;
  height: 974px;
  position: absolute;
`;

const Main2 = styled.div`
  width: 100%;
  height: 350px;
  background-color: #f9f9f9;
  display: flex;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 180px;
  min-width: 1200px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  
`;

const Main3 = styled.div`
  width: 80%;
  height: 800px;
  display: flex;
  border-bottom: 6px solid black;
  margin: 150px auto;
`;

const Main4 = styled.div`
  width: 80%;
  height: 750px;
  display: flex;
  border-bottom: 6px solid black;
  margin: 10px auto;
`;

const Header = styled.div`
  width: 85%;
  min-width: 1000px;
  height: 15%;
  display: flex;
  justify-content: space-between;
  padding: 40px 151px 0 191.5px;

  img {
    margin-left: 30px;
    object-fit: none;
    cursor: pointer;
  }
`;

const Body = styled.div`
  min-width: 1600px;
  height: 50%;
  padding: 170px 150px 0 200px;

`;

const Right = styled.div`
    width: 50%;
    max-width: 700px;
`;

const Right2 = styled.div`
    width: 10%;
`;

const Left = styled.div`
    width: 50%;
  
`;const Left2 = styled.div`
    width: 65%;
`;

const PPuryo = styled.div`
  font-family: ONEMobilePOP;
  margin: 7px 19.5px 0 0;
  font-size: 50px;
  line-height: 1.3;
  letter-spacing: -1px;
  text-align: left;
  color: #fff;
`;

const TitleWhite = styled.div`
  width: 100%;
  line-height: 80px;
  font-size: 50px;
  letter-spacing: -2px;
  color: #fff;
  margin-bottom: 50px;
`;


const Tel = styled.div`
  font-size: 13px;
  letter-spacing: 1px;
  color: #fff;
  //text-align: center;
`;

const Main2Image = styled.div`
  width: 65%;
  margin: 0 auto;
  padding-bottom: 70px;
`;

const Main2aaa = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 70px;
`;

const Tel2 = styled.div`
  font-size: 16px;
  letter-spacing: 2px;
  color: #fff;
  //margin-top: 10px;
  line-height: 30px;
`;

const Store = styled.div`
  margin: 0 0 0 -20px;
  
  img {
    margin-right: 30px;
    cursor: pointer;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 400px;
  padding: 0 0 178px 1px;
  background-color: #484848;
  
  div {
    padding-top: 160px;
    text-align: center;
  }
  
  img {
    margin: 0 50px;
    cursor: pointer;
  }
`;

const FooterFont = styled.div`
    font-size: 17px;
    font-weight: 300;
    line-height: 2;
    text-align: left;
    padding-bottom: 20px;
`;

const InBox = styled.div`
    width: 65%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
    min-width: 1100px;
    max-width: 2500px;
`;

const TopBox = styled.div`
    width: 85%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 0 auto;
    min-width: 900px;
    max-width: 2500px;
`;

const OutBox = styled.div`
  width: 40%;
  min-width: 900px;
  margin: 0 500px 50px 450px;
  font-size: 50px;
  line-height: 70px;
`;

const Main3a1 = styled.div`
  font-size: 20px;
  line-height: 40px;
`;

function OwnerWeb() {
    const handleMoveLogin = async () => {
        document.location.href = 'https://accounts.kakao.com/login?continue=http%3A%2F%2Fpf.kakao.com%2F_IRSys%2Fchat';
    };
    const handleMoveTel = async () => {
        document.location.href = '';
    };
    const handleMoveKakao = async () => {
        document.location.href = 'http://pf.kakao.com/_IRSys/chat';
    };
    const handleMoveGoogle = async () => {
        document.location.href = 'https://play.google.com/store/apps/details?id=com.rightcode.ppuryo_seller';
    };
    const handleMoveApp = async () => {
        document.location.href = 'https://apps.apple.com/kr/app/%EB%BF%8C%EB%A3%8C-%EC%BB%A4%EB%A8%B8%EC%8A%A4/id1590958016';
    };

    return (
        <>
            <Main1>
                <Header>
                    <div>
                        <PPuryo><img src={headerLogo}/></PPuryo>
                    </div>
                    {/*<div>*/}
                    {/*    <img src={ownerLogin} onClick={(e) => handleMoveLogin(e)}/>*/}
                    {/*    <Tel>뿌료 입점문의 1800-5411 </Tel>*/}
                    {/*</div>*/}
                </Header>
                <Body>
                        <img src={ownerMain1Web} style={{ marginBottom: '50px'}}/>
                    <Store>
                        <img src={ownerTel} onClick={(e) => handleMoveTel(e)}/>
                        <img src={ownerKakao} onClick={(e) => handleMoveKakao(e)}/>
                    </Store>
                        <Tel2>전화문의는 전문 상담가와 1:1로 진행됩니다.<br/>(1800-5411 을 눌러주세요)</Tel2>
                </Body>
            </Main1>

            <img src={mainImage2x} style={{ width: '100%', minWidth: '1600px'}}/>

            <Main3>
                <InBox>
                    <Left>
                        <div>
                            <img src={ownerMain1A1} style={{ width: '80%', marginTop: '100px' }}/>
                        </div>
                    </Left>
                    <Right>
                        <img src={ownerMain1} style={{ width: '80%' }}/>
                    </Right>
                </InBox>
            </Main3>

                <Main2Image>
                    <img src={ownerMain2TileWeb} style={{ height: '76%' }}/>
                </Main2Image>
                <Main4>
                    <TopBox>
                        <img src={ownerMain2A1} style={{ height: '73%' }}/>
                        <img src={ownerMain2A2} style={{ height: '75%' }}/>
                        <img src={ownerMain2A3} style={{ height: '73%' }}/>
                    </TopBox>
                </Main4>

            <Main2>
                <InBox>
                    <Left2>
                        <div>
                            <img src={ownerMain3Title}/><br/><br/><br/><br/>
                            <img src={ownerMain3Tel} onClick={(e) => handleMoveTel(e)}/><br/><br/><br/>
                            <Main3a1>
                                궁금한 상항은 문의해주세요. 사장님을 위한 전용 상담 센터입니다.<br/>
                                운영시간 : 월요일 ~ 금요일 09:00 ~ 21:00
                            </Main3a1><br/><br/>
                            <img src={ownerKakao} onClick={(e) => handleMoveKakao(e)}/>
                        </div>
                    </Left2>
                    <Right2>
                        <img src={ownerMain3Icon} style={{ width: '100%', marginTop: '390px'}}/>
                    </Right2>
                </InBox>
            </Main2>

            <Footer>
                <div>
                    <img src={google2} onClick={(e) => handleMoveGoogle(e)}/>
                    <img src={app2} onClick={(e) => handleMoveApp(e)}/>
                </div>
                <FooterFont>
                    (주) 위드미디어&nbsp;&nbsp;&nbsp; 통신판매: 2021-서울송파-0332 &nbsp;&nbsp;&nbsp;사업자등록번호 : 105-88-10965<br/>
                    서울시 송파구 법원로114 엠스테이트 A동 709호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 제휴문의 : help@ppuryo.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 고객문의 : 1800-5411<br/>
                    COPYRIGHT rightcode@naver.com
                </FooterFont>
            </Footer>
        </>
    )
}

export default OwnerWeb;