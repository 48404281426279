import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import OwnerWeb from "./pages/Main/ownerWeb";
import OwnerMobile from "./pages/Main/ownerMobile";

class Routes extends Component {
    render() {
        let indexRoute;
        if (isMobile) {
            indexRoute = <Route exact path='/' component={OwnerMobile} />;
        } else {
            indexRoute = <Route exact path='/' component={OwnerWeb} />;
        }
        return (
            <Router>
                <Switch>
                    {indexRoute}
                    {/*<Route exact path='/owner' component={OwnerWeb} />;*/}
                    {/*<Route exact path='/mOwner' component={OwnerMobile} />;*/}
                </Switch>
            </Router>
        );
    }
}

export default Routes;
